// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#agreement_failure {
  align-items: center;
  height: 100%;
  margin: auto;
  padding: 1rem;
  width: 100%;
}
section#agreement_failure img {
  border-radius: 16px;
  margin: auto;
  width: 10rem;
}
section#agreement_failure h3 {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

section#agreement_failure_report {
  color: rgb(255, 255, 255);
  height: 100vh;
  width: 550px;
  margin: auto;
  padding: 0 1rem;
}
section#agreement_failure_report h3 {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  text-align: center;
}
section#agreement_failure_report p.positive {
  color: #B0FE76;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/pages/bank/agreement/failure/failure.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACEA;EACE,mBAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,WAAA;AAIF;AAFE;EACE,mBAAA;EACA,YAAA;EACA,YAAA;AAIJ;AADE;EACE,mDD2FyB;EC1FzB,iBAAA;EACA,kBAAA;AAGJ;;AACA;EACE,yBDCY;ECAZ,aAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AAEF;AAAE;EACE,mDD6EyB;EC5EzB,iBAAA;EACA,kBAAA;AAEJ;AACE;EACE,cD4BgB;EC3BhB,mDDsEyB;ECrEzB,iBAAA;EACA,gBAAA;EACA,kBAAA;AACJ","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

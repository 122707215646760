import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { YapilyBankDetailsModel } from 'components/models/YapilyModels/YapilyBankDetailsModel';
import { YapilyBankModel } from 'components/models/YapilyModels/YapilyBankModel';
import { checkResponseStatus } from 'http_clients/checkResponseStatus';
import { ReportsHttpClient } from 'http_clients/ReportsHttpClient';
import { SingletonService } from 'singleton';
@autoinject()
export class agreementFailure {
    private report: boolean = false;
    private reportSent: boolean = false;
    private shaking: boolean = false;
    private country;
    private bankName;
    private bank: YapilyBankDetailsModel

    constructor(private router: Router,
        private reportHttpClient: ReportsHttpClient,
        private singleton: SingletonService) {
    }

    activate() {
        this.country = this.singleton.getCountries()
        this.bank = this.singleton.getAddedBank()

    }

    goToHome() {
        this.router.navigateToRoute('home')
    }


    async reportBank() {
        let request = await this.reportHttpClient.fetch('/reportBank/', {
            method: 'POST',
            body: JSON.stringify({
                bankName: this.bankName,
                country: this.country
            })
        })
        let is200Ok = checkResponseStatus(request)
        let response = is200Ok
        this.reportSent = true
    }
    async shakeError() {
        this.shaking = true;
        setTimeout(() => {
            this.shaking = false;
        }, 200);
    }
}
import { autoinject } from 'aurelia-dependency-injection';
import { Router } from 'aurelia-router';
import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { I18N } from "aurelia-i18n";

@autoinject
export class AddAccount {
  private listOfBanks: string = "";
  private brokenBanks: Array<string> = ["Alpenbank" ,"Cassa Raiffeisen*" ,"Deutsche Bank" ,"DNB" ,"Handelsbanken" ,"Intesa Sanpaolo" ,"MBANK Retail Poland" ,"Noris Bank", "SMBC", "Solaris"]

  constructor(private router: Router,private yapilyHttpClient: YapilyHttpClient,private i18n: I18N,) {
    this.getBrokenBanks();
  }

  getBrokenBanks() {
    this.listOfBanks = this.i18n.tr("bank.add_account.broken_banks") + '\n\n\n' + this.brokenBanks;
  }

  goToChooseCountry() {
    this.router.navigateToRoute("choose_country_list")
  }
}

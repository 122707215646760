// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/EU/eubank.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/EU/ellipse_77.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./add_account.scss"></require>
  <section id="add_account" class="flex-column">
    <div id="header" class="flex-row">        
      <img click.delegate="router.navigateBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="bank.add_account.add_account1"></h1>
    </div>
    <div class="svg-container">
      <img id="thumbnail" src="${___HTML_LOADER_IMPORT_1___}">
      <img id="thumbnail_background" src="${___HTML_LOADER_IMPORT_2___}">
    </div>
    <div class="spacer-32"></div>
    <h4 i18n="bank.add_account.add_account2"></h4>
    <div class="spacer-32"></div>
    <span class="gray" i18n="bank.add_account.add_account4"></span>
    <div class="flex-grow"></div>
    <div class="flex-grow" if.bind="brokenBanks.length > 0"></div>
    <button class="btn btn-primary" click.delegate="goToChooseCountry()">
      <span i18n="home.start"></span>
    </button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;
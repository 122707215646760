import { autoinject } from "aurelia-framework";
import { AuthService } from "aurelia-auth";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { SingletonService } from "singleton";
import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { Router } from "aurelia-router";
import BankaService from "services/banka.service";
import { YapilyBankDetailsModel } from "components/models/YapilyModels/YapilyBankDetailsModel";
import { json } from "aurelia-fetch-client";
import { noView, processContent } from "aurelia-framework";
import { State } from "state";
import { connectTo } from "aurelia-store";
import { EventAggregator } from "aurelia-event-aggregator";
import { checkResponseStatus } from "http_clients/checkResponseStatus";

@noView()
@connectTo()
@processContent(false)
@autoinject()
export class getAccountLanding {
  private me: any;
  private params: any;
  private state: State;

  constructor(
    private bank: YapilyBankDetailsModel,
    private yapilyHttpClient: YapilyHttpClient,
    private router: Router,
    private userHttpClient: UsersHttpClients,
    private ea: EventAggregator
  ) {}
  activate(params) {
    this.params = params;
    this.bind();
  }

  async bind() {
    this.bank = this.state.selectedBankToAdd;
    if (this.params.error) {
      await this.failure();
      return;
    }

    await this.storeConsent(this.params);
  }

  async failure() {
    await this.endFirstTimeFlow();
    this.router.navigateToRoute("agreement_failure");
  }

  async storeConsent(params: any) {
    let links = {
      applicationUserId: params["application-user-id"],
    };
    console.log(links)
    await this.postFinalizeBankAccountCreation(links)
    this.endFirstTimeFlow();
    this.router.navigateToRoute("agreement_success");
  }

  async postFinalizeBankAccountCreation(links){
    let httpRequest = await this.yapilyHttpClient.fetch("/finalize-bank-account-creation", {
      method: "POST",
      body: JSON.stringify(links)
    })
    let is200Ok = await checkResponseStatus(httpRequest)
    let response = await is200Ok.json();
    console.log(response)
  }

  async endFirstTimeFlow() {
    let httpRequest = await this.userHttpClient.fetch("/profile", {
      method: "PATCH",
      body: JSON.stringify({
        isFirstTime: false,
      }),
    });
  }
}

// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/x_circle.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./failure.scss"></require>
  <section id="agreement_failure" class="flex-column" if.bind="report == false">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="bank.agreement.agreement_failure0"></h1>
    </div>
    <div class="flex-grow"></div>
    <img src="${___HTML_LOADER_IMPORT_1___}">
    <div class="flex-grow"></div>
    <h3 i18n="bank.agreement.agreement_failure1">Il y a eu une erreur dans l'octroiement de votre consentement.</h3>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" click.delegate="report = true">Report</button>
    <div class="spacer-16"></div>
    <button class="btn btn-secondary" click.delegate="goToHome()" i18n="home.goBackHome">Retourner à l'accueil</button>
    <div class="spacer-default"></div>
  </section>
  <section id="agreement_failure_report" class="flex-column" if.bind="report == true">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="bank.agreement.agreement_failure0"></h1>
    </div>
    <div class="flex-grow"></div>
    <h3 i18n="bank.agreement.agreement_failure_report"></h3>
    <div class="flex-grow"></div>
    <form id="reportform" class="inputs flex-column" submit.delegate="reportBank()">
      <div>
        <input class.bind="shaking ? 'shake' : ''" id="country" type="text" value.bind="country"
          i18n="[placeholder]home.country" required />
      </div>
      <div>
        <input class.bind="shaking ? 'shake' : ''" id="bank" type="text" value.bind="bankName" placeholder="Bank"
          required />
      </div>
    </form>
    <div class="flex-grow" if.bind="reportSent == true"></div>
    <p class="positive" if.bind="reportSent == true" i18n="home.report_sent"></p>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" click.delegate="shakeError()" i18n="home.validate"
      if.bind="!country || !bankName"></button>
    <button class="btn btn-primary" click.delegate="reportBank()" form="reportform" i18n="home.validate"
      if.bind="reportSent == false && country && bankName"></button>
    <button class="btn btn-primary" click.delegate="goToHome()" i18n="home.goBackHome"
      if.bind="reportSent == true"></button>
    <div class="spacer-default"></div>
  </section>
</template>`;
// Exports
export default code;
import { Router } from "aurelia-router";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { autoinject } from "aurelia-dependency-injection";
import { SingletonService } from "singleton";
import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { RecipientsHttpClient } from "http_clients/RecipientsHttpClient";
import { RecipientModel } from "../../../../components/models/RecipientModel";
import { YapilyAccountsModel } from "../../../../components/models/YapilyModels/YapilyAccountsModel";
import { YapilyBankDetailsModel } from "components/models/YapilyModels/YapilyBankDetailsModel";
import ENDPOINTS from "endpoints";
import { YapilyAccountModel } from "components/models/YapilyModels/YapilyAccountModel";
import { ReportsHttpClient } from "http_clients/ReportsHttpClient";
import { NotificationHttpClient } from "http_clients/NotificationHttpClient";
import { I18N } from "aurelia-i18n";
import { EventAggregator } from "aurelia-event-aggregator";
import { State } from "state";
import { connectTo } from "aurelia-store";
@connectTo()
@autoinject
export class AgreementSuccess {
  private bank: YapilyBankDetailsModel;
  private me;
  private accounts: YapilyAccountsModel;
  private errorMessage: string;
  private reportMessage: string;
  private country;
  private bankName;
  private response;
  private isLoading: boolean = false;
  private state: State;

  constructor(
    private singleton: SingletonService,
    private yapilyhttpClient: YapilyHttpClient,
    private router: Router,
    private i18n: I18N,
    private recipientsHttpClient: RecipientsHttpClient,
    private reportHttpClient: ReportsHttpClient,
    private notificationHttpClient: NotificationHttpClient,
    private ea: EventAggregator
  ) {}
  async attached() {
    this.bank = this.singleton.getAddedBank();
    this.me = this.singleton.getMe();
  }

  async createNotificationForAdminReportBank() {
    let request = await this.notificationHttpClient.fetch("/notification", {
      method: "POST",
      body: JSON.stringify({
        owner_id: this.me._id,
        status: "NOT_SEEN",
        notification: "BUGREPORT",
        seen: false,
        notification_fk_id: this.me._id,
        receiverNotification: " ",
        name_fk: this.me.displayName,
        url_pic_fk: this.me.urlPic,
      }),
    });
    let is200Ok = await checkResponseStatus(request);
    let response = await is200Ok.json(request);
  }

  async reportBank(errorMessage) {
    try {
      let request = await this.reportHttpClient.fetch("/reportBank/", {
        method: "POST",
        body: JSON.stringify({
          bankName: this.bankName,
          country: this.country,
          errorMessage: errorMessage,
        }),
      });
      let is200Ok = checkResponseStatus(request);
      let response = is200Ok;
    } catch (e) {
      console.log(e)
    }
  }

  async addMyOwnAccount(accounts) {
    for (let i = 0; i < accounts.data.length; i++) {
      let recipientToAdd: RecipientModel = new RecipientModel();
      recipientToAdd.recipient_id = this.me._id;
      recipientToAdd.iban =
        accounts.data[i].iban ||
        accounts.data[i].accountIdentifications[0].identification ||
        accounts.data[i].id;
      recipientToAdd.name = accounts.data[i].accountNames[0].name;
      let request = await this.recipientsHttpClient.fetch("/recipients", {
        method: "POST",
        body: JSON.stringify(recipientToAdd),
      });
    }
  }

  bind(){
    setTimeout(() => this.router.navigateToRoute("home"), 3000)
  }
}

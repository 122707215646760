// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/logo_white.png", import.meta.url);
// Module
var code = `<template>
  <require from="./users_login.scss"></require>

  <section id="agreements_users_login" class="flex-column">
    <div id="header" class="flex-row">
      <img click.delegate="goToHome()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="bank.agreement.agreements_users1"></h1>
    </div>
    <div class="spacer-32"></div>
    <div class="logo_and_bank row">
      <div class="blackground">
        <img src="${___HTML_LOADER_IMPORT_1___}">
      </div>
      <span>X</span>
      <div class="blackground">
        <img id="bank_img" src.bind="bank.media[0].source" alt="">
      </div>
    </div>
    <div class="flex-grow"></div>
    <div class="all-paragraphs">
      <div class="paragraph">
        <h2 i18n="bank.agreement.agreements_users_login_method"></h2>
        <span class="gray" i18n="bank.agreement.agreements_users_login_method_desc"></span>
      </div>
    </div>
    <div class="flex-grow"></div>
    <span class='error' class.bind="isError ? 'shake' : ''" if.bind='isError'>
      \${errorMessage}
    </span>
    <div class="flex-grow"></div>
    <form class.bind="shaking ? 'shake' : ''" id="loginform" class="inputs flex-column" submit.delegate="login()">
      <div>
        <input id="username" type="text" value.bind="id" placeholder="Username" required 
          />
      </div>
      <div>
        <input ref="inputPsw" id="password" type="password" value.bind="password" i18n="[placeholder]auth.password" required
         />  
        <i click.delegate="showPassword()" if.bind="inputPsw.type === 'text'" class="fas fa-eye eye"></i>
        <i click.delegate="showPassword()" if.bind="inputPsw.type === 'password'" class="fas fa-eye-slash eye"></i>
      </div>
    </form>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" click.delegate="makeAgreement()" ><span i18n="bank.agreement.agreements_users12"></span></button>
    <button class="btn btn-secondary" click.delegate="goToHome()"><span i18n="home.back"></span></button>
  </section>
</template>
`;
// Exports
export default code;
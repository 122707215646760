// Module
var code = `<template>
  <require from="./success.scss"></require>
  <require from="../../../../components/animated_checkmark/animated_checkmark"></require>
  <section id="agreement_success" class="flex-column">
    <div class="flex-grow"></div>
    <div class="click"  click.delegate="router.navigateToRoute('home')" >
      <animated-checkmark></animated-checkmark>
    </div>
    <div class="flex-grow"></div>
  </section>
</template>
`;
// Exports
export default code;
import { json } from "aurelia-fetch-client";
import { autoinject } from "aurelia-dependency-injection";
import { Router } from "aurelia-router";
import { SingletonService } from "singleton";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { YapilyBankDetailsModel } from "components/models/YapilyModels/YapilyBankDetailsModel";
import { I18N } from "aurelia-i18n";
import { ReportsHttpClient } from "http_clients/ReportsHttpClient";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";

@connectTo()
@autoinject()
export class AgreementsUsers {
  private bank: YapilyBankDetailsModel;
  private isOurConditionsAccepted: boolean;
  private isYapilyConditionsAccepted: boolean;
  private isError: boolean = false;
  private shaking: boolean = false;
  private isLoading: boolean = false;
  private response;
  private reportMessage: string;
  private preAuthorizationFlow: boolean = false;
  private preEmbededAuthorizationFlow: boolean = false;
  private errorMessage: string;
  private country;
  private bankName;
  private state: State;
  private psuIdBank: Array<string> = [
    "Alpenbank",
    "Cassa Centrale Raiffeisen Dell Alto Adige",
    "Cassa Raiffeisen Alta Pusteria",
    "Cassa Raiffeisen Alta Venosta",
    "Cassa Raiffeisen Bassa Atesina",
    "Cassa Raiffeisen Bassa Vall Isarco",
    "Cassa Raiffeisen Bassa Venosta",
    "Cassa Raiffeisen Campo Di Trens",
    "Cassa Raiffeisen Castelrotto-Ortisei",
    "Cassa Raiffeisen Della Val Passiria",
    "Cassa Raiffeisen Della Valle Isarco",
    "Cassa Raiffeisen Di Brunico",
    "Cassa Raiffeisen Di Dobbiaco",
    "Cassa Raiffeisen Di Funes",
    "Cassa Raiffeisen Di Lagundo",
    "Cassa Raiffeisen Di Lasa",
    "Cassa Raiffeisen Di Marlengo",
    "Cassa Raiffeisen Di Merano",
    "Cassa Raiffeisen Di Nova Ponente Aldino",
    "Cassa Raiffeisen Di Parcines",
    "Cassa Raiffeisen Di Scena",
    "Cassa Raiffeisen Di Tesimo",
    "Cassa Raiffeisen Di Villabassa",
    "Cassa Raiffeisen Etschtal",
    "Cassa Raiffeisen Gherdeina",
    "Cassa Raiffeisen Laces",
    "Cassa Raiffeisen Lana",
    "Cassa Raiffeisen Monguelfo-Casiestesido",
    "Cassa Raiffeisen Oltradige",
    "Cassa Raiffeisen Prato-Tubre",
    "Cassa Raiffeisen Schlern-Rosengarten",
    "Cassa Raiffeisen Silandro",
    "Cassa Raiffeisen Tirolo",
    "Cassa Raiffeisen Tures-Aurina",
    "Cassa Raiffeisen Ultimo S.Pancrazio-Lauregno",
    "Cassa Raiffeisen Val Badia",
    "Cassa Raiffeisen Val Sarentino",
    "Cassa Raiffeisen Vandoies",
    "Cassa Raiffeisen Wipptal",
    "Cassa Rurale Di Bolzano",
    "Cassa Rurale Di Salorno",
    "Deutsche Bank",
    "DNB",
    "Handelsbanken Business UK", //(PSU-corporate-ID)
    "Intesa Sanpaolo S.p.A", //(PSU-IP)
    "Noris Bank",
    "Raiffeisen Bank Croatia",
    "Raiffeisen Landesbank",
    "Casa Centrale Raiffeisen dell Alto Adige",
    "SMBC",
    "Solaris", //(PSU-IP)
  ];

  constructor(
    private router: Router,
    private singleton: SingletonService,
    private yapilyHttpClient: YapilyHttpClient,
    private i18n: I18N,
    private reportHttpClient: ReportsHttpClient,
    private store: Store<State>
  ) {}

  attached() {}

  bind() {
    this.bank = this.state.selectedBankToAdd;
    this.country = this.state.selectCountryBank;
    this.checkPreAuthorizationFlow();
    this.checkEmbeddedAuthorizationFlow();
    this.country = this.singleton.getCountries();
    this.bankName = this.bank.fullName;
  }

  /* check if we need preAuthorization flow from yapily */
  checkPreAuthorizationFlow() {
    try {
      for (let i = 0; i < this.bank.features.length; i++) {
        if (this.bank.features[i] == "INITIATE_PRE_AUTHORISATION") {
          this.preAuthorizationFlow = true;
          return this.preAuthorizationFlow;
        } else {
          this.preAuthorizationFlow = false;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  checkEmbeddedAuthorizationFlow() {
    try {
      for (let i = 0; i < this.bank.features.length; i++) {
        if (
          this.bank.features[i] == "INITIATE_EMBEDDED_DOMESTIC_SINGLE_PAYMENT"
        ) {
          this.preEmbededAuthorizationFlow = true;
          return this.preEmbededAuthorizationFlow;
        } else {
          this.preEmbededAuthorizationFlow = false;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  /* end of cheking */
  /**
   * This function checks that the conditions are accepted and attempts
   * to create an agreement towards Yapily
   * @returns the concluded agreement between User Banka and Yapily
   */
  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }
  async makeAgreement() {
    try {
      this.isLoading = true;
      this.isError = false;
      if (!this.isOurConditionsAccepted || !this.isYapilyConditionsAccepted) {
        this.isError = true;
        this.isLoading = false;
        return;
      }

      let method = "POST";
      let body = json({
        institutionId: this.bank.id,
      });
      let params = {
        method: method,
        body: body,
      };

      if (this.preEmbededAuthorizationFlow == true) {
        this.router.navigateToRoute("agreements_users_login");
      } else {
        if (this.preAuthorizationFlow == true) {
          this.response = await this.yapilyHttpClient.fetch(
            "/pre-auth-requests",
            params
          );
        } else {
          this.response = await this.yapilyHttpClient.fetch(
            "/account-auth-requests",
            params
          );
        }
      }

      if (this.response.status == 422) {
        this.isError = true;
        this.errorMessage = this.i18n.tr(
          "home.error.agreement_users_error_consent"
        );

        setTimeout(() => {
          this.router.navigateToRoute("account_overview");
        }, 7500);
        return;
      }

      if (this.response.status == 400) {
        this.isError = true;
        this.errorMessage = this.i18n.tr(
          "home.error.reauthorize_users_error_error"
        );
        this.isLoading = false;
      }
      if (this.response.status == 403) {
        this.isError = true;
        this.errorMessage = this.i18n.tr(
          "home.error.reauthorize_users_error_access"
        );
        this.isLoading = false;
      }
      if (this.response.status == 404) {
        this.isError = true;
        this.errorMessage = this.i18n.tr(
          "home.error.reauthorize_users_error_bank"
        );
        this.isLoading = false;
      }
      let agreement: any = await this.response.json();
      this.goToYapily(agreement.data.authorizationUrl);
    } catch (error) {
      console.log(error);
      this.reportBank();
    }
  }
  
  async goToYapily(url: string) {
    window.location.replace(url);
  }

  async reportBank() {
    try {
      let request = await this.reportHttpClient.fetch("/reportBank/", {
        method: "POST",
        body: JSON.stringify({
          bankName: this.bankName,
          country: this.country,
          errorMessage: this.response.status,
        }),
      });
      let is200Ok = checkResponseStatus(request);
      let response = is200Ok;
      this.reportMessage = this.i18n.tr("home.report_message");
    } catch (e) {
      console.log(e);
    }
  }

  goToHome() {
    this.router.navigateToRoute("home");
  }
}
